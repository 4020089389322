export const NAVBAR_NAMES = {
  DASHBOARD: 'navbar.dashboard',
  PATIENTS: 'navbar.patients.list',
  PATIENTS_DETAIL_OVERVIEW: 'navbar.patients.overview',
  PATIENTS_DETAIL_DOCTORS: 'navbar.patients.doctors',
  PATIENTS_DETAIL_STORAGE: 'navbar.patients.storage',
  PATIENTS_DETAIL_ANAMNESIS: 'navbar.patients.anamnesis',
  PATIENTS_DETAIL_CHECKUPS: 'navbar.patients.checkups',
  PATIENTS_DETAIL_MEASUREMENTS: 'navbar.patients.measurements',
  PATIENTS_DETAIL_FAMILY: 'navbar.patients.family',
  NAMES: 'navbar.settings',
  SETTINGS: 'navbar.settings',
  CHAT: 'navbar.chat',
  ORDERS: 'navbar.orders',
  CUSTOM_MEDICATIONS: 'navbar.customMedications',
  DOCTORS: 'navbar.doctors.list',
}
